import React from "react";




const PublicationItem = ({ order, Data, itemVisible, setItemVisible }) => {
    let Publication = Data[order];
    let AuthorObject = Publication.author ? Publication.author : "";
    let AuthorList = AuthorObject ? AuthorObject.map(author => author.family + " " + author.given).join(", ") : "";
    let Title = Publication["title"] ? Publication["title"] : "";
    let DOI = Publication["DOI"] ? Publication["DOI"] : "";
    let Abstract = Publication["abstract"] ? Publication["abstract"] : "";
    let YearPub = "["+ (Publication["issued"]["date-parts"][0][0] ?? "") +"]";
    return (


        <div className={"py-3"}>
            {AuthorList ? <div className={"text-xs"}>{AuthorList}</div> : null}
            {
                Title
                    ?
                    <a href={"https://dx.doi.org/" + DOI} target='_blank' rel="noreferrer" >
                        <div className={"text-lg text-color1"}>
                            <b>
                               {YearPub}{Title}
                            </b>
                        </div>
                    </a>
                    : null
            }
            {
                Abstract
                    ? (
                        <div className={"cursor-pointer"}>
                            <h1 onClick={() => itemVisible === order ? setItemVisible(-1) : setItemVisible(order)}
                                className={itemVisible === order
                                    ? "text-color1 text-xs" : "text-color2 text-xs"}>
                                {itemVisible === order ? "Cerrar" : "Ver Resumen"}
                            </h1>
                            {itemVisible === order ? (
                                <p className={"text-xs p-3 bg-yellow-100"}>{Abstract}</p>
                            )
                                : null}
                        </div>
                    ) : null}
        </div>


    )
}

export default PublicationItem;