import * as React from "react";
import TemplatePage1 from "@components/TemplatePage/TemplatePage";
import styled from "styled-components";
import AuthorList from "@components/AuthorList/AuthorList";
import Publications from '@datasources/Publications';
import PublicationItem from "@components/PublicationItem/PublicationItem";
import { useState } from "react";



const Wrapper = styled.div`
`;


const IndexPage = () => {

  const [itemVisible, setItemVisible] = useState(-1)


  return (
    <TemplatePage1
      title={"Publicaciones"}
      content={(
        <Wrapper >
          {Publications["data"].length>=1?Publications["data"].map((publication,i)=><PublicationItem order={i} Data={Publications["data"]} itemVisible={itemVisible} setItemVisible={setItemVisible}  key={"publications_page"+i}/>):"Aún no hay publicaciones."}
        </Wrapper >
      )}
    />
  )
};

export default IndexPage;




