import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
    width:100%;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    background-color: ${props=> props.hero?"#36789BE8":"#36789BAA"};
    color: #FFFFFF;
    font-weight: 500;
    margin: 0.5rem 0 0.5rem 0;
    border-radius: 20px;

    h1{
        text-transform: uppercase;
        font-size: 1rem;
        color: #FFFFFF;
        border-bottom: ${props=>props.hero?"none":"solid 2px #36789B"};
    }
    h2{
        font-size: 1rem;
        margin-bottom: 0.5rem;
    }
    h3{
        font-size: 0.7rem;
        line-height: 0.8rem;
        padding: 0.1rem 0 0.1rem 0;
        margin-bottom: 0.25rem;
    }
    a{
        font-size: 0.9rem;
        line-height: 1rem;
        padding: 0.5rem; 
        color:white;
        cursor: pointer;
        text-align: center;
        display:block;
    }

    @media (min-width: 768px) { 
        width:90%;
        flex-direction: column;
        align-items: center;
    }

    @media (min-width: 1024px) { 
        width:80%;
        margin-right: 0rem;
    }
`;

const TextZone = styled.div`
    text-align: left;
    width: 100%;
    margin-left: 0.5rem;
    margin-right: 0.5rem;

    @media (min-width: 768px) { 
        text-align: center;
        max-width:100%;
    }
`

const RightConten=({title="",content=(<></>)})=>{
    return (
    <Wrapper>
        <TextZone>
        <h1 className={"text-xl text-center"}>{title}</h1>
        <h2>{content}</h2>
        
        </TextZone>
        </Wrapper>
    )
}

export default RightConten;