import React, {useState} from "react";
import Publications from "@datasources/Publications";
import PublicationItem from "../PublicationItem/PublicationItem";



export const SearchPublicationByAuthor =(authorData={"given": "","family": ""})=> {
	var PublicationList = [];
    if (Publications===""){
    }else{
        for(var i=0; i<Publications["data"].length; i++){
            if (Publications["data"][i]["author"]!==undefined){
                for(var j=0; j<Publications["data"][i]["author"].length;j++){

                    let publicationsGiven=Publications["data"][i]["author"][j]["given"].replace(/\s+/g, '').replace("-","").replace("_","").replace(".","").toLowerCase().replace("á","a").replace("é","e").replace("í","i").replace("ó","o").replace("ú","u");

                    let publicationsFamily=Publications["data"][i]["author"][j]["family"].replace(/\s+/g, '').replace("-","").replace("_","").replace(".","").toLowerCase().replace("á","a").replace("é","e").replace("í","i").replace("ó","o").replace("ú","u");

                    let searchGiven = authorData["given"].replace(/\s+/g, '').replace("-","").replace("_","").replace(".","").toLowerCase().replace("á","a").replace("é","e").replace("í","i").replace("ó","o").replace("ú","u");

                    let searchFamily=authorData["family"].replace(/\s+/g, '').replace("-","").replace("_","").replace(".","").toLowerCase().replace("á","a").replace("é","e").replace("í","i").replace("ó","o").replace("ú","u");

                    let publicationWord1=publicationsGiven+publicationsFamily;
                    let publicationWord2=publicationsFamily+publicationsGiven;

                    let searchWord1=searchGiven+searchFamily;
                    let searchWord2=searchFamily+searchGiven;
                    
                    if(publicationWord1===searchWord1||publicationWord1===searchWord2||publicationWord2===searchWord1||publicationWord2===searchWord2){
                        PublicationList.push(Publications["data"][i]);
                    }
                }
            }
        }
    }
	return PublicationList;
}

const AuthorPublications = ({authorData={"given": "Ezequiel","family": "Garcia-Elorrio"}}) =>{
    const AuthorPublications = SearchPublicationByAuthor(authorData);
    const [itemVisible, setItemVisible] = useState(-1);
    return(
        <div className="w-full flex flex-col flex-nowrap justify-center center-items content-center divide-solid divide-y-2">
            <div>
                {AuthorPublications.map((publication,i)=><PublicationItem order={i} Data={AuthorPublications} itemVisible={itemVisible} setItemVisible={setItemVisible}  key={"Public"+i}/>)}
            </div>
        </div>
    )
};

export default AuthorPublications;