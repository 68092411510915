import { Link } from 'gatsby';
import React from 'react';
import { SearchPublicationByAuthor } from '../AuthorPublications/AuthorPublications';
import RightContent from '../RightContent/RightContent';
const Authors = [
    {
        "family": "Garcia-Elorrio",
        "given": "Ezequiel",
        "number": 0,
    },
    {
        "family": "García",
        "given": "Patricia J",
        "number": 0,
    }
]



const AuthorList = () => {
    Authors.map((author, i) => Authors[i]["number"] = SearchPublicationByAuthor(Authors[i]).length)

    return (
        <RightContent
            title={"Investigadores"}
            content={(<>
                {Authors.map((author, i) => (
                    <Link
                        className={"text-white py-3 text-left md:text-right w-full"}
                        to={"/rd/" + Authors[i]["given"].replace(/\s+/g, '').replace("-", "").replace("_", "").toLowerCase().replace("á", "a").replace("é", "e").replace("í", "i").replace("ó", "o").replace("ú", "u") + Authors[i]["family"].replace(/\s+/g, '').replace("-", "").replace("_", "").toLowerCase().replace("á", "a").replace("é", "e").replace("í", "i").replace("ó", "o").replace("ú", "u")} key={"author" + i}>
                        
                            {/* {Authors[i]["given"] + " " + Authors[i]["family"] + " (" + Authors[i]["number"] + ")"} */}
                            {Authors[i]["given"] + " " + Authors[i]["family"]} 
                        
                    </Link>
                ))}
            </>)}
        />
    )

}

export default AuthorList;